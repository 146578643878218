import { sortAlpha } from './sortAlpha';

const COLORS = [
  {
    color: 'Amarillo',
    hex: '#FFB800',
  },
  {
    color: 'Amarillo Claro',
    hex: '#FFD25C',
  },
  {
    color: 'Amarillo Oscuro',
    hex: '#e0a410',
  },
  {
    color: 'Naranja',
    hex: '#F6704D',
  },
  {
    color: 'Naranja Claro',
    hex: '#fd9b82',
  },
  {
    color: 'Naranja Oscuro',
    hex: '#de3308',
  },
  {
    color: 'Turquesa',
    hex: '#73b3ab',
  },
  {
    color: 'Turquesa Claro',
    hex: '#9fcfc9',
  },
  {
    color: 'Turquesa Oscuro',
    hex: '#41746d',
  },
  {
    color: 'Verde',
    hex: '#33A854',
  },
  {
    color: 'Verde Claro',
    hex: '#75d590',
  },
  {
    color: 'Verde Oscuro',
    hex: '#288943',
  },
  {
    color: 'Azul',
    hex: '#0E3663',
  },
  {
    color: 'Azul Claro',
    hex: '#6381a1',
  },
  {
    color: 'Azul Oscuro',
    hex: '#07213f',
  },
  {
    color: 'Rosado',
    hex: '#FABAC2',
  },
  {
    color: 'Rosado Claro',
    hex: '#fccbd1',
  },
  {
    color: 'Rosado Oscuro',
    hex: '#f48b97',
  },
  {
    color: 'Rojo',
    hex: '#F55266',
  },
  {
    color: 'Rojo Claro',
    hex: '#f38794',
  },
  {
    color: 'Rojo Oscuro',
    hex: '#d94d5e',
  },
  {
    color: 'Celeste',
    hex: '#3885FE',
  },
  {
    color: 'Celeste Claro',
    hex: '#87b3f8',
  },
  {
    color: 'Celeste Oscuro',
    hex: '#235db0',
  },
];
export const listColors = sortAlpha( COLORS , 'color');

export function getColorRandom(num: number) {
  return listColors[num*3].hex.toUpperCase();
}

export function handleColorHex(hex){
  if(!hex){
    return;
  }
  const result =  listColors.find(itemColor => itemColor.hex.toUpperCase() === hex.toUpperCase());
  return result ? result.color : null;
}