import React from 'react';

import {FormControl, FormLabel, Text, Tooltip} from '@chakra-ui/react';

import {TextareaControl} from 'formik-chakra-ui';

import {InfoIcon} from '../assets/icons';

interface ITextAreaInputProps {
  title: string;
  name: string;
  tooltip: string;
  placeholder: string;
  required?: boolean;
  description?: string;
}

export function TextAreaInput(props: ITextAreaInputProps) {
  const {title, placeholder, required, name, tooltip, description} = props;
  return (
    <FormControl mt={5} isRequired={required}>
      <FormLabel fontWeight='bold' alignItems='center' display='flex'>
        <Tooltip label={tooltip} placement='right-start'>
          <InfoIcon w={17} h={17} mr={3} />
        </Tooltip>
        {title}
      </FormLabel>
      <Text mt={-2} mb={1}>
        {description}
      </Text>
      <TextareaControl
        name={name}
        textareaProps={{
          size: 'sm',
          placeholder: placeholder,
          bg: 'white',
          variant: 'filled',
          color: 'black',
          _focus: {
            bg: 'white !important',
            color: 'black',
          },
        }}
      />
    </FormControl>
  );
}
