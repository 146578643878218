import answersFilter from '../slices/answersFilterSlice';
import assignKpiFilterSlice from '../slices/assignKpiFilterSlice';
import backEventsSlice from '../slices/backEventsSlice';
import billFilterSlice from '../slices/billFilterSlice';
import calendarFilterSlice from '../slices/calendarFilterSlice';
import calendarShowFiltersSlice from '../slices/calendarShowFiltersSlice';
import calendarTypeSlice from '../slices/calendarTypeSlice';
import coordinatesSlice from '../slices/coordinatesSlice';
import disclosureFormCalendarSlice from '../slices/disclosureFormCalendarSlice';
import documentsSlice from '../slices/documentsSlice';
import eventCodeSlice from '../slices/eventCodeSlice';
import createActiveSlice from '../slices/formSlices/createActiveSlice';
import droppedSlice from '../slices/formSlices/droppedSlice';
import formShowComponentsSlice from '../slices/formSlices/formShowComponentsSlice';
import formSlice from '../slices/formSlices/formSlice';
import formStateSlice from '../slices/formSlices/formStateSlice';
import infographicSlice from '../slices/formSlices/infographicSlice';
import sectionSelectedSlice from '../slices/formSlices/sectionSelectedSlice';
import sectionSlice from '../slices/formSlices/sectionSlice';
import paginationMonthSlice from '../slices/paginationMonthSlice';
import paginationWeekSlice from '../slices/paginationWeekSlice';
import tourFilterSlice from '../slices/tourFilterSlice';
import tourShowFiltersSlice from '../slices/tourShowFiltersSlice';
import toursSlice from '../slices/toursSlice';
import viewMoreSlice from '../slices/viewMoreSlice';
import viewReportStateSlice from '../slices/viewReportSlice';
import workdaySlice from '../slices/workdaySlice';

export default {
  calendarType: calendarTypeSlice,
  backEvents: backEventsSlice,
  eventCode: eventCodeSlice,
  workdayState: workdaySlice,
  calendarFilter: calendarFilterSlice,
  calendarShowFilters: calendarShowFiltersSlice,
  disclosureFormCalendarState: disclosureFormCalendarSlice,
  viewMore: viewMoreSlice,
  paginationWeek: paginationWeekSlice,
  form: formSlice,
  formState: formStateSlice,
  createActiveState: createActiveSlice,
  section: sectionSlice,
  sectionSelected: sectionSelectedSlice,
  formShowComponents: formShowComponentsSlice,
  dropped: droppedSlice,
  viewReportState: viewReportStateSlice,
  tour: toursSlice,
  tourShowFilters: tourShowFiltersSlice,
  tourFilter: tourFilterSlice,
  document: documentsSlice,
  coordinate: coordinatesSlice,
  assignKpiFilter: assignKpiFilterSlice,
  billFilter: billFilterSlice,
  answersFilter: answersFilter,
  infographic: infographicSlice,
  paginationMonth: paginationMonthSlice,
};
