import {all} from 'redux-saga/effects';

import sagaCalendarType from './sagaCalendarType';
import sagaCoordinates from './sagaCoordinates';
import sagaDocuments from './sagaDocuments';
import sagaForm from './sagaForm';
import sagaTours from './sagaTours';

export default function* rootSaga() {
  yield all([
    sagaCalendarType(),
    sagaForm(),
    sagaTours(),
    sagaDocuments(),
    sagaCoordinates(),
  ]);
}
